import * as React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, graphql, Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import useBlogData from '../../hooks/use-blog-data';

import Layout from '../../components/layout';
import Rule from '../../components/rule';
import Section from '../../components/section';

import BlogListItem from '../../components/blog/blog-list-item';

import InlineImage from '../../components/blog/inline-image'; // @see: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/
import TryFreeButton from '../../components/blog/try-free-button'; // @see: https://www.gatsbyjs.com/docs/mdx/importing-and-using-components/

export const query = graphql`
  query QueryBlog($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/blog/" }) {
      id
      frontmatter {
        title
        subtitle
        html_head_title
        description
        author
        author_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        date(formatString: "MMMM D, YYYY")
        date_iso: date(formatString: "YYYY-MM-DDTHH:mm:ss")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        hide_hero
        inline_images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      excerpt
      body
      slug
    }
  }
`;

const findPreviousBlogPosts = (id, limit) => {
  const allBlogs = useBlogData();

  const index = allBlogs.findIndex((blog) => blog.id === id);

  // console.log(' ------> findPreviousBlogPosts ', {
  //   id,
  //   allBlogs,
  //   length: allBlogs.length,
  //   index,
  // });

  const maxIndex =
    index + limit > allBlogs.length ? allBlogs.length : index + 1 + limit;

  return index >= 0 ? allBlogs.slice(index + 1, maxIndex) : [];
};

const BlogPost = ({ data }) => {
  if (!data.mdx) {
    // for policies & products
    return null;
  }

  const { mdx } = data;
  const { id, frontmatter, body, excerpt, slug } = mdx;

  const {
    title,
    subtitle,
    // eslint-disable-next-line camelcase
    html_head_title,
    description,
    author,
    // eslint-disable-next-line camelcase
    author_image,
    date,
    // eslint-disable-next-line camelcase
    date_iso,
    // eslint-disable-next-line camelcase
    hero_image,
    // eslint-disable-next-line camelcase
    hide_hero,
    inline_images,
  } = frontmatter;

  const previousBlogPosts = findPreviousBlogPosts(id, 6);

  const image = getImage(hero_image);
  const avatar = getImage(author_image);

  const htmlHeadTitle =
    html_head_title && html_head_title.length > 0 ? html_head_title : title;

  const metaDescription =
    description && description.length > 0 ? description : excerpt;

  const openGraph = {
    url: `https://www.dsapps.dev/blog/${slug}`,
    title: htmlHeadTitle,
    description: metaDescription,
    type: 'article',
  };

  const metaTags = [
    {
      property: 'twitter:description',
      content: metaDescription,
    },
    {
      property: 'twitter:image',
      content: 'https://www.dsapps.dev/images/website-preview-800x400.jpg',
    },
    {
      property: 'article:author',
      content: author,
    },
    {
      property: 'article:published_time',
      content: date_iso,
    }, // 8am on the date of publish
  ];

  return (
    <>
      <GatsbySeo
        language="en"
        title={htmlHeadTitle}
        openGraph={openGraph}
        description={excerpt}
        metaTags={metaTags}
      />
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <script src={withPrefix('/vendor/tocbot/tocbot.min.js')}></script>
        <link
          rel="stylesheet"
          href={withPrefix('/vendor/tocbot/tocbot.css')}
        ></link>
        <script src={withPrefix('/js/blogpost.js')}></script>
      </Helmet>
      <Layout pageTitle={htmlHeadTitle}>
        <Section innerClassName="max-w-screen-xl px-5 lg:px-16 py-16 mx-auto">
          <section className="body-font">
            <div className="flex flex-col py-4">
              <div>
                <div className="flex flex-col sm:flex-row">
                  <div className="lg:w-1/3" />
                  <div className="lg:w-2/3 sm:mx-10 lg:mx-0">
                    <h2 className="mb-1 font-sans text-xs font-medium tracking-widest text-blue-500 title-font">
                      <Link to="/blog/" className="uppercase animate-underline">
                        Blog
                      </Link>
                    </h2>
                    <Rule />
                    <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-3xl title-font">
                      {title}
                    </h1>
                    {description && description.length > 0 ? (
                      <div className="py-4 text-lg md:text-xl blog-subtitle">
                        {description}
                      </div>
                    ) : (
                      description
                    )}

                    <div className="flex items-center justify-between my-4">
                      <div className="flex items-center space-x-4">
                        <GatsbyImage
                          className="object-cover object-center rounded-full w-10 "
                          image={avatar}
                          alt={author}
                        />
                        <div className="text-sm">
                          <div className="uppercase tracking-wide  text-gray-800 dark:text-gray-200 ">
                            {author}
                          </div>
                          <div className="  text-gray-500 dark:text-gray-400">
                            Last updated: <span title={date_iso}>{date}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {hide_hero ? null : (
                      <>
                        {image && (
                          <div className="h-64 overflow-hidden dsapps-rounded-lg my-8">
                            <GatsbyImage
                              className="object-cover object-center w-full h-full"
                              image={image}
                              alt={data.mdx.frontmatter.hero_image_alt}
                            />
                          </div>
                        )}
                      </>
                    )}

                    <aside className="toc-container lg:fixed lg:top-0 py-2">
                      <div className="toc-heading font-sans text-xs font-medium tracking-widest uppercase">
                        Table of Contents
                      </div>
                      <div
                        id="toc"
                        className="rounded-lg bg-white dark:bg-black my-4 pt-4 text-sm"
                      ></div>
                    </aside>

                    <div
                      id="blogpost-body"
                      className="mb-4 text-lg leading-relaxed text-left content-body animate-underline-anchors"
                    >
                      <MDXProvider components={{ InlineImage, TryFreeButton }}>
                        <MDXRenderer localImages={inline_images} slug={slug}>
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Section>
        <Section
          className="older-posts"
          innerClassName="max-w-screen-xl px-5 lg:px-16 py-16 mx-auto"
        >
          <section className="body-font">
            {previousBlogPosts.length > 0 ? (
              <div>
                <Rule />
                <h2 className="pt-8">You may also be interested in...</h2>

                <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-10 mt-10">
                  {previousBlogPosts.map((node) => (
                    <BlogListItem node={node} />
                  ))}
                </div>
              </div>
            ) : null}
          </section>
        </Section>
      </Layout>
    </>
  );
};

export default BlogPost;
