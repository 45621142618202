import * as React from 'react';

const TryFreeButton = ({
  url,
  slug = 'blog',
  tenUsersFree = false,
  isMondayApp = false,
}) => {
  const utmSuffix = `${
    url && url.indexOf('?') > 0 ? '&' : '?'
  }utm_source=dsapps.dev&utm_medium=blog&utm_campaign=${slug.replaceAll(
    '/',
    ''
  )}`;
  return (
    <div className="my-8 text-center">
      <a
        className="inline-flex px-6 py-2 text-lg text-white bg-purple-600 border-0 rounded focus:outline-none hover:bg-purple-800 dark:hover:bg-purple-500"
        href={`${url}${utmSuffix}`}
        target="_blank"
      >
        Try it for free
      </a>

      {tenUsersFree && (
        <div className="mt-4 text-sm">
          {"There's no charge for up to 10 users."}
        </div>
      )}

      {isMondayApp && (
        <div className="mt-4 text-sm">
          {'Free trial for 14 days. No charge for up to 2 users.'}
        </div>
      )}
    </div>
  );
};

export default TryFreeButton;
