import * as React from 'react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';

const InlineImage = ({ image, alt }) => (
  <a href={getSrc(image)}>
    <GatsbyImage
      className="object-cover object-center w-full h-full"
      image={getImage(image)}
      alt={alt}
    />
  </a>
);
export default InlineImage;
